import "./service-card.styles.css";
import { MdOutlineArrowRightAlt } from "react-icons/md";

const ServiceCard = ({ icon, title, description, fontColor, bgColor }) => {
  return (
    <div className="svc-card" style={{ backgroundColor: bgColor }}>
      <img src={icon} alt={title} style={{color: fontColor}}/>
      <h1 style={{ color: fontColor }}>{title}</h1>
      <p style={{ color: fontColor }}>{description}</p>
      <a href="/services" className="svc-card-btn" style={{ color: fontColor }}>
        <MdOutlineArrowRightAlt className="svc-card-btn-icon" size="1.3rem" style={{ fill: fontColor }}/>
      </a>
    </div>
  );
};

export default ServiceCard;
