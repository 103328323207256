import "./services.styles.css";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import Nav from "../nav/nav.component";
import Footer from "../footer/footer.component";

const Services = () => {
  return (
    <>
      <Nav />
      <div className="services">
        <h1 className="svc-ttl">Our Services</h1>
        <div className="svc-cntnr">
          <div className="svc" style={{ borderColor: "#898f56" }}>
            <img
              src="/images/Individual Session.webp"
              alt="Individual Session"
            />
            <h2 style={{ textTransform: "uppercase" }}>1:1 Coaching</h2>
            <h1>Individual Support</h1>
            <p>
              Our 1:1 coaching service provides you with a curated road map
              tailored to your unique goals. We focus on optimizing your health
              through various dimensions, including nutrition, weight loss, gut
              health, stress management, energy and stamina, brain health, and
              more.
            </p>
            <ul>
              <li>Personalized assessment and health plan</li>
              <li>Focus on nutrition, weight loss, stress, and energy</li>
              <li>Free 15-minute goal evaluation</li>
              <li>Tailored advice on diet and lifestyle</li>
            </ul>
            <button
              className="svc-btn"
              style={{
                borderColor: "#898f56",
                "--background-color": "#898f56",
                "--font-color": "#fcf7ed",
              }}
            >
              Book Now
              <MdOutlineArrowRightAlt
                size="1.3rem"
                className="svc-icon"
                style={{
                  "--font-color": "#fcf7ed",
                }}
              />
            </button>
          </div>
          <div>
            <div className="svc" style={{ borderColor: "#d4d084" }}>
              <img src="/images/Group Session.webp" alt="Group Session" />
              <h2 style={{ textTransform: "uppercase" }}>Group Session</h2>
              <h1>Group Engagement</h1>
              <p>
                Experience health and wellness support in a collaborative group
                setting, where you can connect with others on a similar journey.
                Participate in dynamic, interactive sessions for small groups,
                conveniently hosted at a local venue or online to fit your
                schedule.
              </p>
              <ul>
                <li>Affordable rates for small groups</li>
                <li>90-minute Q&A sessions in-person or via Zoom</li>
                <li>Covers nutrition, stress, and wellness topics</li>
                <li>Builds accountability and group support</li>
              </ul>
              <button
                className="svc-btn"
                style={{
                  borderColor: "#d4d084",
                  "--background-color": "#d4d084",
                }}
              >
                Book Now
                <MdOutlineArrowRightAlt size="1.3rem" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
