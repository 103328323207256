import "./story.styles.css";
import Footer from "../footer/footer.component";
import Nav from "../nav/nav.component";

const Story = () => {
  return (
    <>
      <Nav />
      <div className="story-cntnr">
        <div className="story-top">
        <img
            src="/images/John Johnson Before.webp"
            alt="John Johnson"
            className="story-img"
          />
          <img
            src="/images/John Johnson After.webp"
            alt="John Johnson"
            className="story-img"
          />
          <div className="story">
            <div>
              <h1>Our Story</h1>
              <h2 style={{ fontSize: "large" }}>Meet John Johnson</h2>
            </div>
            <p>
              Years ago, at 5'6" and over 240 lbs, I faced numerous health
              issues: diabetes, coronary artery disease, liver disease, kidney
              stones, severe acne, eczema, and debilitating IBS. Doctors warned
              I wouldn't make it to 40 without major medical intervention.{" "}
              <br />
              <br />
              At 32, weighing 244 lbs, I hit rock bottom. My diet was unhealthy,
              filled with processed foods and sugar. Nutrition was not a concern
              until I met Don Lemmon, a celebrity trainer, and nutritionist. His
              guidance led me to a plant-based/vegan lifestyle, which
              dramatically improved my health.
              <br />
              <br />I now practice hyper-nutrition to combat my nutrient
              absorption issues and continue to refine a fact-based, clinically
              proven method for promoting health and longevity.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Story;
