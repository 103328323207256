import "./testimonials.styles.css";
import ClientTestimonials from "./testimonials";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react";

const Testimonials = () => {
  const [pos, setPos] = useState(0);

  useEffect(() => {
    const increment = setTimeout(() => {
      setPos((prev) => (prev === 2 ? 0 : prev + 1));
    }, 5000);

    return () => clearTimeout(increment);
  }, [pos]);

  return (
    <div className="tst">
      <FaArrowLeft
        size="1.6rem"
        className="arr"
        onClick={() => setPos((prev) => (prev === 0 ? 2 : prev - 1))}
      />
      <div className="tst-cntnr">
        <h1>What Our Clients are Saying</h1>
        <p
          style={{ fontSize: "2.8rem", textAlign: "center", margin: "40px 0" }}
        >
          "{ClientTestimonials[pos].description}"
        </p>
        <h2 style={{ textTransform: "uppercase" }}>
          {ClientTestimonials[pos].name}
        </h2>
      </div>
      <FaArrowRight
        size="1.6rem"
        className="arr"
        onClick={() => setPos((prev) => (prev === 2 ? 0 : prev + 1))}
      />
    </div>
  );
};

export default Testimonials;
