import "./pop-up.styles.css";
import { useState } from "react";
import { MdCancel, MdOutlineArrowRightAlt } from "react-icons/md";

const PopUp = () => {
  const [hidden, setHidden] = useState(
    sessionStorage.getItem("hidden")
      ? JSON.parse(sessionStorage.getItem("hidden"))
      : false
  );

  const hidePopUp = () => {
    setHidden(true);
    sessionStorage.setItem("hidden", JSON.stringify(true));
  };

  return (
    <div className="pop-up" style={{ display: hidden ? "none" : "" }}>
      <div>
        <h1>Hey!</h1>
        <p>
          Struggling to stay on track with your wellness goals? Get personalized
          coaching and support tailored to your unique needs to help you achieve
          lasting health and vitality.
        </p>
      </div>
      <div className="pop-up-btn-cntnr">
        <MdCancel size="1.3rem" className="pop-up-btn" onClick={hidePopUp} />
        <a href="/services" className="pop-up-btn">
          Get on Track <MdOutlineArrowRightAlt size="1.3rem" />
        </a>
      </div>
    </div>
  );
};
export default PopUp;
