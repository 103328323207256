import "./nav.styles.css";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const navigate = useNavigate();

  return (
    <div className="nav-cntnr">
      <div className="banner-bg">
        <p className="banner-txt">Book a 15 minute free consultation</p>
      </div>
      <div className="nav">
        <a href="/story">Our Story</a>
        <a href="/services">Services</a>
        <img
          src="/images/John-Ji Logo.svg"
          alt="John-Ji Logo"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        <a href="/resources">Resources</a>
        <a href="/book">Book</a>
      </div>
    </div>
  );
};

export default Nav;
