import "./footer.styles.css";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <img src="/images/John-Ji Logo.svg" alt="John-Ji Logo" />
        <h3>© Copyright John-Ji Health and Wellness, 2024</h3>
      </div>
      <div className="footer-contact">
        <div>
          <h3>Start Your Journey</h3>
          <div className="footer-in">
            <input type="text" placeholder="Your Email" />
            <h3 style={{ cursor: "pointer" }}>Subscribe→</h3>
          </div>
        </div>
        <div>
          <h3>Email Us</h3>
          <a href="mailto:the.nourishment.guru@gmail.com">
            the.nourishment.guru@gmail.com
          </a>
        </div>
      </div>
      <div className="footer-links">
        <a href="/story">Our Story</a>
        <a href="/services">Services</a>
        <a href="/resources">Resources</a>
        <a href="/book">Book</a>
      </div>
      <div className="social-media">
        <FaFacebookF
          size="2.5rem"
          fill="#fcf7ed"
          className="social-icon"
          onClick={() =>
            (window.location.href = "https://www.facebook.com/Knocko")
          }
        />
        <FaInstagram
          size="2.5rem"
          fill="#fcf7ed"
          className="social-icon"
          onClick={() =>
            (window.location.href =
              "https://www.instagram.com/the.nourishment.guru/")
          }
        />
      </div>
    </div>
  );
};

export default Footer;
