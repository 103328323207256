import "./App.css";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import Landing from "./components/landing/landing.component";
import Services from "./components/services/services.component";
import Story from "./components/story/story.component";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/story" element={<Story />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
